import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stockpurchaseteam/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stockpurchaseteam/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stockpurchaseteam/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/stockpurchaseteam/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stockpurchaseteam/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stockpurchaseteam/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stockpurchaseteam/del', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getAllUserByPurchaseTeamId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stockpurchaseteam/getAllUserByPurchaseTeamId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    export(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stockpurchaseteam/export', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
